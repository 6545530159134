import { api, handleResponse } from "./apiServices";

export const login = (user) => api().post(`/auth/login`, user).then(handleResponse);

export const newCaptcha = (user) => api().post(`/auth/new-captcha`, user).then(handleResponse);

export const checkCaptcha = (user) => api().post(`/auth/check-captcha`, user).then(handleResponse);

export const check = (token) => api(token).post(`/auth/check`).then(handleResponse);

export const forgotPassword = (data) => api().post(`/auth/forgot-password`, data).then(handleResponse);

export const resetPassword = (data) => api().post(`/auth/reset-password`, data).then(handleResponse);

export const register = (data) => api().post(`/auth/registration/`, data).then(handleResponse);

export const getNotification = (token) => api(token).get("/users/notifications").then(handleResponse);

export const updateUserPassword = (token, user) => api(token).post(`/users/update-password`, user).then(handleResponse);

export const logout = (token) => {
  return api().post("/auth/logout", token).then(handleResponse);
};

export const getUserProfile = (token, id) => api(token, id).get("./users/get-profile").then(handleResponse);
export const getUsers = (token, id, data) =>
  typeof id == "string" && id !== ""
    ? api(token)
        .get("/users/get/" + id)
        .then(handleResponse)
    : api(token).post("/users/get/", data).then(handleResponse);

export const addUser = (token, formdata) => api(token).post("/users/add", formdata).then(handleResponse);

export const updateUser = (token, formdata) => api(token).post("/users/update", formdata).then(handleResponse);

export const preLoadData = (token) => {
  return api(token).get("/users/dashboard-preload-data").then(handleResponse);
};

export const changePassword = (data) => {
  return api().post("/auth/customer/changePassword", data).then(handleResponse);
};

export const getUserFilter = (token, project) => api(token).post(`/users/user-filter`, project).then(handleResponse);

export const updateStatus = (token, data) => api(token).post(`/users/update-status`, data).then(handleResponse);

export const checkUsername = (data) => api().post(`auth/check-username/`, data).then(handleResponse);

export const deleteFilesDB = (token, data) =>
  api(token).delete("/users/deleteFile", { data: data }).then(handleResponse);

export const getLocationList = (token, flag) => api(token).get(`/location/location-list/${flag}`).then(handleResponse);

export const EmailisVerify = (token) => api().get(`/auth/verify-email/${token}`).then(handleResponse);

let user = {
  login,
  check,
  getUsers,
  addUser,
  updateUser,
  logout,
  changePassword
};

export default user;
