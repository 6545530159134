import React, { Suspense, useEffect, useState } from "react";
import Aside from "./components/Aside";
import { connect } from "react-redux";
import "./css/App.scss";
import Main from "./components/Main";
import { setUserTypes, fetching, error, success } from "./redux/actions/navigationActions";
import { preLoadData } from "./redux/services/userServices";

import FixedLoader from "./components/loader";

const App = (props) => {
  const { user, token, setUserTypes, fetching, error, success } = props;
  const [rtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };
  
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const preLoadAllData = () => {
    fetching();
    preLoadData(token).then(
      (data) => {
        if (data.code === 5) {
          setUserTypes({
            userTypes: data._userTypes,
            userRoles: data._userRoles,
          });
          success();
        } else error(data.message);
      },
      (err) => error(err)
    );
  };

  useEffect(() => {
    user.userType && preLoadAllData();
    // eslint-disable-next-line
  }, [user]);
  return (
    <React.Fragment>
      <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
        <Aside image={image} collapsed={collapsed} toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
        <Suspense fallback={<FixedLoader />}>
          <Main
            {...props}
            image={image}
            toggled={toggled}
            collapsed={collapsed}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleImageChange={handleImageChange}
          >
            {props.children}
          </Main>
        </Suspense>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  token: state.authentication.token,
});

const mapDispatchToProps = (dispatch) => ({
  setUserTypes: (props) => dispatch(setUserTypes(props)),
  fetching: (message) => dispatch(fetching(message)),
  success: (message) => dispatch(success(message)),
  error: (message) => dispatch(error(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
