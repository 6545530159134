import axios from "axios";
import { clearLocalStorage, decryptResponse } from "../../utils/index";

const BACKEND_URI = process.env.REACT_APP_BACKEND_URI ? process.env.REACT_APP_BACKEND_URI : "";

export const api = (token) => {
  if (typeof token == "string" && token.split(".").length === 3)
    return axios.create({
      withCredentials: true,
      baseURL: `${BACKEND_URI}/api/v1/`,
      headers: { accesstokenrrm: token }
    });
  else
    return axios.create({
      withCredentials: true,
      baseURL: `${BACKEND_URI}/api/v1/`
    });
};

export const handleResponse = (res) => {
  // console.log(res.config.url,"== === ==",decryptResponse(res.data))
  const data = process.env.REACT_APP_ENV === "development" ? res.data : decryptResponse(res.data);
  if (data.code === 6) {
    window.location.href = "/login";
    clearLocalStorage(["token_rapid", "companyName"]);
  }
  if (res.status !== 200 || data.error || (res.status === 200 && data.code === 0)) {
    const error = data.message ? data.message : data.error;
    return Promise.reject(error);
  }
  return data;
};
