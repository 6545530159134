import { api, handleResponse } from "./apiServices";

export const getPRKByID = (token, id) => api(token).get(`/prk/prkGetById/${id}`).then(handleResponse);

export const getSwapTimeApi = (token, data) => api(token).post(`/prk/swap-time`,data).then(handleResponse);

export const getCashInApi = (token, data) => api(token).post(`/prk/cash-in`,data).then(handleResponse);

export const getPRK = (token, data) => api(token).post("/prk/prkGet", data).then(handleResponse);
///////////////////
export const updatePRKLocation = (token, data) => api(token).post("/prk/add-location", data).then(handleResponse);


export const getDateFilter = (token, data) => api(token).get(`/prk/date-filter/${data.tid}/${data.type}`).then(handleResponse);

export const getTIDFilter = (token, data) => api(token).post("/prk/tid-filter", data).then(handleResponse);

export const getCashManagement = (token, data) =>
api(token).post("/prk/cash-management-list", data).then(handleResponse);

export const getRedeemManagement = (token, data) => api(token).post("/prk/prk-redeem", data).then(handleResponse);

export const getCardView = (token) => api(token).get("/prk/generateQrCode").then(handleResponse);

export const terminalTicket = (token, data) => api(token).post("/prk/terminal-ticket", data).then(handleResponse);