let navigationConstants = {
  SET_USER_ROLES: "NAVIGATION_SET_USER_ROLES",
  SET_USER_TYPES: "NAVIGATION_SET_USER_TYPES",
  FETCHING: "FETCHING",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_FAILURE: "FETCH_FAILURE",
  FETCH_CLEAR: "FETCH_CLEAR",
  IS_FETCHING_NOTIFICATIONS: "NAVIGATION_IS_FETCHING_NOTIFICATIONS",
  FETCHED_NOTIFICATIONS: "NAVIGATION_FETCHED_NOTIFICATIONS",
  HANDLE_NOTIFICATION_CLICK: "NAVIGATION_HANDLE_NOTIFICATION_CLICK",
};
export default navigationConstants;
