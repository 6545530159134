import { createAction } from "@reduxjs/toolkit";
import { error } from "./navigationActions";
import userServices from "../services/userServices";
import userConstants from "../constants/userConstants";
import navigationConstants from "../constants/navigationConstants";
import { clearLocalStorage } from "../../utils";

export const login = (user, history) => {
  const request = (user) => createAction(userConstants.LOGIN_REQUEST)(user);
  const success = (user) => createAction(userConstants.LOGIN_SUCCESS)(user);
  const failure = (user) => createAction(navigationConstants.FETCH_FAILURE)(user);
  const { location } = history;
  return (dispatch) => {
    dispatch(request({ username: user.username }));
    if (user.username.trim().length > 0)
      userServices.login(user).then(
        (data) => {
          if (data._user) {
            dispatch(
              success({
                user: data._user,
                token: data._token,
                todayDate: data.todayDate,
                currentType: data._currentType,
              })
            );
            localStorage.setItem("token_rapid", data._token);
            localStorage.setItem("companyName", data._user.companyName);
            let prevUrl = localStorage.getItem("prevUrl")
            if(prevUrl == null){
              history.push(
                location.state && location.state.from && location.state.from.pathname.includes("/")
                  ? location.state.from.pathname
                  : "/",
                {
                  from: location,
                }
              );
            }
            else{
              history.push(prevUrl);
            }
            
          } else {
            const setCaptcha = (payload) => createAction(userConstants.SET_CAPTCHA)(payload);
            dispatch(setCaptcha(""));
            if(data.hasOwnProperty("captcha")){
              dispatch(setCaptcha(data.captcha));
            }
            dispatch(failure(data.message));
          }
        },
        (err) => {
          dispatch(failure(err));
        }
      );
    else dispatch(failure());
  };
};

export const setCheck = (data, history) => {
  const request = (user) => createAction(userConstants.LOGIN_REQUEST)(user);
  const success = (user) => createAction(userConstants.LOGIN_SUCCESS)(user);
  const failure = (user) => createAction(userConstants.LOGIN_FAILURE)(user);
  return (dispatch) => {
    dispatch(request());
    if (data) {
      dispatch(
        success({
          user: {
            ...data._user,
            profilePicture: {
              ...data._user.profilePicture,
            },
          },
          currentType: data._currentType,
          token: data.token,
          todayDate: data.todayDate,
        })
      );
    } else {
      dispatch(failure());
    }
  };
};
export const logout = (history) => {
  return (dispatch) => {
    clearLocalStorage(["token_rapid","companyName","prevUrl"])
    userServices.logout().then(
      () => {
        history.push("/login");
        window.location.reload();
        dispatch(createAction(navigationConstants.FETCH_SUCCESS("Logout successfull")));
        dispatch(createAction(userConstants.LOGOUT)());
      },
      (err) => {
        dispatch(error(err + "\n\rSigning out in 5 seconds..."));
        document.cookies = "";
        setTimeout(() => {
          history.push("/login");
          window.location.reload();
          dispatch(createAction(navigationConstants.FETCH_SUCCESS("Logout successfull")));
          dispatch(createAction(userConstants.LOGOUT)());
        }, 5000);
      }
    );
  };
};

export const setCaptcha = (payload) => createAction(userConstants.SET_CAPTCHA)(payload);

const userActions = {
  login,
  setCheck,
  logout,
  setCaptcha
};

export default userActions;
