const shortCodes = {
  en: {
    title: "Xopple Admin",
    sidebarTitle: "Super Admin",
    description: "Description",
    dashboard: "Dashboard",
    users: "Users",
    "user-types": "User Type",
    account: "Account",
    inventory: "Inventory",
    location: "Location",
    maintenance: "Maintenance",
    license: "License",
    bill: "Bill",
    reports: "Reports",
    invoice: "Invoice",
    terminals: "Terminals",
    machine: "Machines",
    parts: "Parts",
    prk: "PRK",
    customers: "Customers",
    components: "Components",
    dropdown: "Dropdown",
    submenu: "Submenu",
    multiLevel: "Multi Level",
    collapsed: "Collapsed",
    rtl: "RTL",
    image: "Background image",
    new: "NEW",
    withPrefix: "With Prefix",
    withSuffix: "With Suffix",
    viewSource: "View Source",
  },
};

export default shortCodes;
