import React from "react";
import "../css/loader.css";

const Loader = props => {
  return (
    <div>
      <div className="loading">Loading</div>
      {props.children}
    </div>
  );
};
export default Loader;
