import React, { useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import { logout } from "../redux/actions/userActions";
import RolesAuth from "./RolesAuth";
import newlogo from "../images/powered-by-prominent-logo-white.png";
import logo from "../images/RR-logo.png";
import logoIcon from "../images/RR-icon-white-bg.png";

import {
  FaTachometerAlt,
  FaUserAlt,
  FaGamepad,
  FaCalculator,
  FaFileInvoiceDollar,
  FaWindowMaximize,
  FaTools,
  FaMapMarkerAlt,
  FaFileInvoice
} from "react-icons/fa";
import { NavLink, withRouter, useLocation } from "react-router-dom";

const Aside = (props) => {
  const { collapsed, toggled, handleToggleSidebar, user, userType } = props;
  const [imageOne, setImageOne] = useState(logo);
  const [imageTwo, setImageTwo] = useState(logoIcon);

  const intl = useIntl();
  const location = useLocation();
  const navigationIconArr = [
    {
      name: "users",
      link: user.userType === 11 ? "/admin/users" : "/users",
      priority: 7,
      role: "user",
      icon: <FaUserAlt />
    },
    {
      name: "invoice",
      link: "/invoice",
      priority: 2,
      role: "invoice",
      icon: <FaFileInvoiceDollar />
    },
    {
      name: "account",
      link: "/account",
      priority: 1,
      role: "account",
      icon: <FaCalculator />
    },
    {
      name: "inventory",
      link: "/inventory",
      priority: 3,
      role: "inventory",
      icon: <FaWindowMaximize />
    },
    {
      name: "PRK",
      link: "/prk",
      priority: 4,
      role: "prk",
      icon: <FaGamepad />
    },
    {
      name: "location",
      link: "/location",
      priority: 5,
      role: "location",
      icon: <FaMapMarkerAlt />
    },
    {
      name: "maintenance",
      link: "/maintenance",
      priority: 6,
      role: "maintenance",
      icon: <FaTools />
    },
    // {
    //   name: "bill",
    //   link: "/bill",
    //   priority: 8,
    //   role: "maintenance",
    //   icon: <FaTools />
    // },
    {
      name: "Report",
      link: "/reports",
      priority: 9,
      role: "reports",
      icon: <FaTools />
    }
  ];
  navigationIconArr.sort((a, b) => (a.priority > b.priority ? 1 : -1));

  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={() => {
        if (toggled === false) {
          setImageOne("");
          handleToggleSidebar();
        } else {
          setImageTwo("");
          handleToggleSidebar();
        }
      }}
    >
      <SidebarHeader>
        <div className="sidebarImage">
          <img src={collapsed ? (imageTwo?imageTwo:"") : (imageOne?imageOne:"")} alt="Prominentt Games" width="100%" className="p-0"></img>
        </div>
        <div className="d-flex justify-content-center menuCompanyName">
          <span>{collapsed ? "" : localStorage.getItem("companyName")}</span>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle" className={`${location.pathname === "/" ? "active" : ""} p-0 my-4 mr-1`}>
          <MenuItem icon={<FaTachometerAlt />}>
            <NavLink
              to={"/"}
              key={"dashboard"}
              onClick={() => handleToggleSidebar(false)}
              title={"Dashboard"}
            ></NavLink>
            {intl.formatMessage({ id: "dashboard" })}
          </MenuItem>
        </Menu>

        {navigationIconArr.map((x) => {
          return (
            <RolesAuth roleKey={x.role} key={x.name}>
              <Menu
                iconShape="circle"
                className={`${location.pathname.includes(x.link) ? "active" : ""} p-0 my-4 mr-1`}
              >
                <MenuItem icon={x.icon}>
                  <NavLink
                    to={x.link}
                    key={x.name}
                    onClick={() => handleToggleSidebar(false)}
                    title={intl.formatMessage({ id: x.name })}
                  ></NavLink>
                  {intl.formatMessage({ id: x.name })}
                </MenuItem>
              </Menu>
            </RolesAuth>
          );
        })}
        {userType != 2 && (
          <RolesAuth roleKey={`invoice`} key={"bill"}>
            <Menu iconShape="circle" className={`${location.pathname === "/bill" ? "active" : ""} p-0 my-4 mr-1`}>
              <MenuItem icon={<FaFileInvoice />}>
                <NavLink to={"/bill"} key={"bill"} onClick={() => handleToggleSidebar(false)} title={"Bill"}></NavLink>
                Bill
              </MenuItem>
            </Menu>
          </RolesAuth>
        )}
      </SidebarContent>

      <SidebarFooter className="text-center">
        <div className="sidebar-btn-wrapper">
          <div>
            <img src={newlogo} alt="Prominentt Games" width="100%" className="p-0"></img>
          </div>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentType: state.authentication.currentType,
  userType: state.authentication.user.userType
});

const mapDispatchToProps = (dispatch) => ({
  logout: (history) => dispatch(logout(history))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Aside));
