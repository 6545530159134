import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "./components/loader";
import App from "./app";
import userActions from "./redux/actions/userActions";
import RolesAuth from "./components/RolesAuth";
import { IntlProvider } from "react-intl";
import Alert from "./components/alert";
import messages from "./utils/shortCodes";
import { check } from "./redux/services/userServices";
import { clearLocalStorage, getLogin } from "./utils";

const DecryptComponent = lazy(() => import("./components/decryptComponent"));
const Login = lazy(() => import("./components/login"));
const Register = lazy(() => import("./components/register"));
const Dashboard = lazy(() => import("./components/dashboard"));

const Users = lazy(() => import("./components/users/User"));
const UsersTable = lazy(() => import("./components/users/UsersTable"));
const UserView = lazy(() => import("./components/users/UserView"));
const SignatureModal = lazy(() => import("./components/SignaturePadModal"));
const TermsAndCondition = lazy(() => import("./components/termsAndCondition"));
const Unsubscribe = lazy(() => import("./components/unsubscribe"));
const ResetPassword = lazy(() => import("./components/resetPassword"));
const Inventory = lazy(() => import("./components/Inventory/Inventory"));
const InventoryTable = lazy(() => import("./components/Inventory/InventoryTable"));
const InventoryView = lazy(() => import("./components/Inventory/InventoryView"));

const Account = lazy(() => import("./components/Account/Account"));
const AccountTable = lazy(() => import("./components/Account/AccountTable"));
const AccountCategory = lazy(() => import("./components/Account/AccountCategory"));

// Location
const Location = lazy(() => import("./components/Location/Location"));
const LocationTable = lazy(() => import("./components/Location/LocationTable"));
const LocationAdd = lazy(() => import("./components/Location/LocationAdd"));
const LocationView = lazy(() => import("./components/Location/LocationView"));

const Maintenance = lazy(() => import("./components/Maintenance/Maintenance"));
const MaintenanceTable = lazy(() => import("./components/Maintenance/MaintenanceTable"));

const Bill = lazy(() => import("./components/Bill/bill"));
const BillTable = lazy(() => import("./components/Bill/billTable"));
const BillView = lazy(() => import("./components/Bill/billView"));

const Invoice = lazy(() => import("./components/Invoice/invoice"));
const InvoiceTable = lazy(() => import("./components/Invoice/invoiceTable"));
const InvoiceView = lazy(() => import("./components/Invoice/invoiceView"));
const InvoiceAdd = lazy(() => import("./components/Invoice/addInvoice"));

const Report = lazy(() => import("./components/Report/report"));
const ReportTable = lazy(() => import("./components/Report/reportTable"));
const InventoryViewTable = lazy(() => import("./components/Inventory/InventoryViewTable"));
const InventoryBrandViewTable = lazy(() => import("./components/Inventory/InventoryBrandViewTable"));

const VerifyEmail = lazy(() => import("./components/VerifyEmail"));

const PRK = lazy(() => import("./components/PRK/prk"));
const PRKTable = lazy(() => import("./components/PRK/PRKTable"));
// const PRKView = lazy(() => import("./components/PRK/PRKView"));
const PrkCashReport = lazy(() => import("./components/PRK/PrkCashReport"));

const Router = (props) => {
  const { history, setCheck } = props;
  const location = useLocation();
  const [locale] = useState("en");
  const [loading, setloading] = useState(true);

  if (location.pathname !== "/login" && location.pathname !== "/") {
    localStorage.setItem("prevUrl", location.pathname);
  }

  async function init() {
    // await check(history);
    setloading(true);
    if (localStorage.getItem("token_rapid")) {
      await check(localStorage.getItem("token_rapid")).then(
        async (data) => {
          if (data._user) {
            await setCheck(data, history);
            // history.push("/admin");
          } else {
            setCheck(null, history);
            clearLocalStorage(["token_rapid", "companyName"]);
            history.push("/login");
            // dispatch(failure());
          }
        },
        (err) => {
          setCheck(null, history);
          clearLocalStorage(["token_rapid", "companyName"]);
          history.push("/login");
        }
      );
    } else {
      history.push("/login");
    }
    setloading(false);
  }
  useEffect(() => {
    if (
      location.pathname.includes("/auth/verify-email/") ||
      location.pathname.includes("/unsubscribe") ||
      location.pathname.includes("/reset-new-password")
    ) {
      setloading(false);
    } else {
      init();
    }
    // eslint-disable-next-line
  }, []);
  return (
    !loading && (
      <Suspense fallback={<Loader />}>
        <Alert></Alert>
        <Switch>
          <Route
            path="/admin"
            render={(props) => (
              <IntlProvider locale={locale} messages={messages[locale]}>
                <App {...props}>
                  <Switch>
                    <Route path="/admin/login" exact render={(props) => <Login {...props} />}></Route>
                    <Route path="/admin/users" exact render={(props) => <UsersTable {...props} />}></Route>
                    <Route path="/admin/users/:id" exact render={(props) => <UserView {...props} />}></Route>
                  </Switch>
                </App>
              </IntlProvider>
            )}
          ></Route>
          {process.env.REACT_APP_ENV === "development" && (
            <Route path="/decrypt" exact render={(props) => <DecryptComponent {...props} />} />
          )}
          <Route path="/auth/verify-email/:token" exact render={(props) => <VerifyEmail {...props} />} />
          {getLogin() && location.pathname == "/login" ? (
            <Route render={() => <Redirect to="/" />} />
          ) : (
            <Route path="/login" exact render={(props) => <Login {...props} />} />
          )}
          <Route path="/register" exact render={(props) => <Register {...props} />} />
          <Route path="/signature" render={(props) => <SignatureModal {...props} />} />
          <Route path="/termsAndCondition/:flag" render={(props) => <TermsAndCondition {...props} />} />
          <Route path="/unsubscribe" render={(props) => <Unsubscribe {...props} />} />
          <Route path="/reset-new-password/:token" render={(props) => <ResetPassword {...props} />} />
          <Route
            path="/"
            render={(props) => (
              <IntlProvider locale={locale} messages={messages[locale]}>
                <App {...props}>
                  <Switch>
                    <Route path="/" exact render={(props) => <Dashboard {...props} />} />
                    <Route
                      path="/users"
                      render={(props) => (
                        <Users {...props}>
                          <Switch>
                            <Route
                              path="/users/profile"
                              exact
                              render={(props) => <UserView {...props} currentUserFetch={true} />}
                            />
                            <RolesAuth roleKey="user">
                              <Route
                                path="/users/:id"
                                render={(props) => <UserView {...props} key={props.match.params.id} />}
                              />
                              <Route path="/users" exact render={(props) => <UsersTable {...props} />} />
                            </RolesAuth>
                          </Switch>
                        </Users>
                      )}
                    />
                    <Route
                      path="/location"
                      // exact
                      render={(props) => (
                        <Location {...props}>
                          <Route
                            // exact
                            path="/location/edit/:id"
                            render={(props) => <LocationAdd {...props} />}
                          />
                          <Route
                            // exact
                            path="/location/add"
                            render={(props) => <LocationAdd {...props} />}
                          />
                          <Route
                            // exact
                            path="/location/v/:id"
                            render={(props) => <LocationView {...props} />}
                          />
                          <Route path="/location" exact render={(props) => <LocationTable {...props} />} />
                          <Route path="/location/type/:type" exact render={(props) => <LocationTable {...props} />} />
                        </Location>
                      )}
                    />

                    <Route
                      path="/inventory"
                      render={(props) => (
                        <Inventory {...props}>
                          <Switch>
                            <RolesAuth roleKey="inventory">
                              <Route
                                path="/inventory/view/:id"
                                exact
                                render={(props) => <InventoryView {...props} />}
                              />
                              <Route
                                path="/inventory/model/view"
                                exact
                                render={(props) => <InventoryViewTable {...props} />}
                              />
                              <Route
                                path="/inventory/brand/view"
                                exact
                                render={(props) => <InventoryBrandViewTable {...props} />}
                              />
                              <Route path="/inventory" exact render={(props) => <InventoryTable {...props} />} />
                              <Route
                                path="/inventory/type/:type"
                                exact
                                render={(props) => <InventoryTable {...props} />}
                              />
                            </RolesAuth>
                          </Switch>
                        </Inventory>
                      )}
                    />

                    <Route
                      path="/maintenance"
                      render={(props) => (
                        <Maintenance {...props}>
                          <Switch>
                            <RolesAuth roleKey="maintenance">
                              <Route path="/maintenance" exact render={(props) => <MaintenanceTable {...props} />} />
                              <Route
                                path="/maintenance/:lid"
                                exact
                                render={(props) => <MaintenanceTable {...props} />}
                              />
                            </RolesAuth>
                          </Switch>
                        </Maintenance>
                      )}
                    />

                    <Route
                      path="/bill"
                      render={(props) => (
                        <Bill {...props}>
                          <Switch>
                            <RolesAuth roleKey="invoice">
                              <Route path="/bill" exact render={(props) => <BillTable {...props} />} />
                              <Route path="/bill/v/:id" render={(props) => <BillView {...props} />} />
                            </RolesAuth>
                          </Switch>
                        </Bill>
                      )}
                    />

                    <Route
                      path="/invoice"
                      render={(props) => (
                        <Invoice {...props}>
                          <Switch>
                            <RolesAuth roleKey="invoice">
                              <Route path="/invoice" exact render={(props) => <InvoiceTable {...props} />} />
                              <Route exact path="/invoice/v/:id" render={(props) => <InvoiceView {...props} />} />
                              <Route exact path="/invoice/add" render={(props) => <InvoiceAdd {...props} />} />
                              <Route
                                exact
                                path="/invoice/add/:locationId"
                                render={(props) => <InvoiceAdd {...props} />}
                              />
                              <Route exact path="/invoice/edit/:id" render={(props) => <InvoiceAdd {...props} />} />
                            </RolesAuth>
                          </Switch>
                        </Invoice>
                      )}
                    />
                    <Route
                      path="/account"
                      render={(props) => (
                        <Account {...props}>
                          <Switch>
                            <Route path="/account/view/:id" exact render={(props) => <Account {...props} />} />
                            <Route path="/account" exact render={(props) => <AccountTable {...props} />} />
                            <Route path="/account/:id" exact render={(props) => <AccountTable {...props} />} />
                            <Route
                              path="/account/category/list"
                              exact
                              render={(props) => <AccountCategory {...props} />}
                            />
                          </Switch>
                        </Account>
                      )}
                    />

                    <Route
                      path="/reports"
                      render={(props) => (
                        <Report {...props}>
                          <Switch>
                            <Route path="/reports" exact render={(props) => <ReportTable {...props} />} />
                          </Switch>
                        </Report>
                      )}
                    />

                    <Route
                      path="/prk"
                      render={(props) => (
                        <PRK {...props}>
                          <Switch>
                            <RolesAuth roleKey="prk">
                              <Route path="/prk" exact render={(props) => <PRKTable {...props} />} />
                              <Route
                                path="/prk/view/:type/:tid"
                                exact
                                render={(props) => <PrkCashReport {...props} />}
                              />
                              <Route path="/prk/cash/report" exact render={(props) => <PrkCashReport {...props} />} />
                            </RolesAuth>
                          </Switch>
                        </PRK>
                      )}
                    />
                    <Route path="*" render={() => <Redirect to="/" />} />
                  </Switch>
                </App>
              </IntlProvider>
            )}
          ></Route>
          {getLogin() ? (
            <Route default render={() => <Redirect to="/" />} />
          ) : (
            <Route default render={() => <Redirect to="/login" />} />
          )}
        </Switch>
      </Suspense>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    userTypes: state.navigation.users.userTypes
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCheck: (history) => {
      dispatch(userActions.setCheck(history));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Router));
