import navigationConstants from "../constants/navigationConstants";
import { createAction } from "@reduxjs/toolkit";
import { getNotification } from "../services/userServices";

export const getNotifications = () => {
  const isFetchingNotifications = (props) => createAction(navigationConstants.IS_FETCHING_NOTIFICATIONS)(props);
  const notificationsFetchSuccess = (props) => createAction(navigationConstants.FETCHED_NOTIFICATIONS)(props);

  return (dispatch, getState) => {
    const token = getState().authentication.token;
    dispatch(isFetchingNotifications());
    getNotification(token)
      .then((data) => {
        if (data.code === 5) {
          dispatch(notificationsFetchSuccess(data));
        } else dispatch(error());
      })
      .catch((err) => dispatch(error(err)));
  };
};
export const handleNotificationClick = (notification, history, hasPermission) => {
  const handleModalSwitch = (props) => createAction(navigationConstants.HANDLE_NOTIFICATION_CLICK)(props);
  if (!hasPermission) return (d) => d;
  switch (notification.notificationType) {
    case "transaction":
      return (d) => {
        d(
          handleModalSwitch({
            type: "transaction",
            value: {
              currentTransaction: { _id: notification._id },
              isTransactionViewModal: true,
              reRender: Math.random(),
            },
          })
        );
        history.push("/admin/transaction", history.location);
      };
    case "pendingMachine":
      return (d) => {
        d(
          handleModalSwitch({
            type: "machines",
            value: {
              isEditing: false,
              isMachineAddModalOpen: true,
            },
          })
        );
        history.push("/admin/machine", history.location);
      };

    default:
      return (d) => d;
  }
};
export const setUserTypes = (props) => createAction(navigationConstants.SET_USER_TYPES)(props);
export const fetching = (message) => createAction(navigationConstants.FETCHING)(message);
export const error = (message) => createAction(navigationConstants.FETCH_FAILURE)(message);
export const success = (message) => createAction(navigationConstants.FETCH_SUCCESS)(message);
export const clear = (message) => createAction(navigationConstants.FETCH_CLEAR)(message);

let navigationAction = {
  fetching,
  error,
  success,
  clear,
  // getNotifications,
  // handleNotificationClick
};

export default navigationAction;
