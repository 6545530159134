import React from "react";
import { connect } from "react-redux";
import navigationActions from "../redux/actions/navigationActions";

import { Toast, ToastBody, ToastHeader } from "reactstrap";

const Alert = (props) => {
  const { isAlertOpen, message, closeAlert } = props;
  const returnError = () => {
    switch (typeof message) {
      case "string":
        return message;
      case "object":
        try {
          if (message.insufficientStock) {
            const insufficientStock = message.insufficientStock;
            return Object.keys(insufficientStock)
              .filter((x) => insufficientStock[x])
              .map((x) => (
                <div key={x}>
                  <span>{x} is out of stock!</span>
                </div>
              ));
          }
          if (message.collidingParts) {
            return message.collidingParts.map((x) => (
              <div key={`${x[0]}-${x[1]}`}>
                <span>{`${x[0]}: ${x[1]} already exists`}</span>
              </div>
            ));
          }
          if (message.message === "Network Error") return <span>Network Error, Please try again later.</span>;
          else if (message.length && message.length > 0)
            return message.map((x) => (
              <span key={`${x.param} ${x.msg}`}>
                {`${x.msg} for ${x.param.split(".").pop()}`}
                <br />
              </span>
            ));
          return JSON.stringify(message);
        } catch (e) {
          return "Error while performing operation!";
        }
      default:
        return typeof message + "\n" + JSON.stringify(message);
    }
  };
  if (props.autoDismiss) setTimeout(() => isAlertOpen && closeAlert(), 5000);
  return (
    <React.Fragment>
      {isAlertOpen && message && (
        <div id="overlay" role="alert">
          {/* <Toast className={`bg-${props.isError ? "danger" : "success" } my-2 rounded`} isOpen={isAlertOpen}>
        <ToastHeader toggle={closeAlert} icon={props.isError ? "danger" : "success" }>
        {props.isError ? "Error" : "Success"}
        </ToastHeader>
        <ToastBody>{returnError()}</ToastBody>
      </Toast> */}
          <div
            aria-live="polite"
            aria-atomic="true"
            className="d-flex justify-content-center align-items-center"
            isOpen={isAlertOpen}
            delay="5000"
          >
            <div
              className={`toast ${props.isError ? "error" : "success"}-toast animate__animated animate__slideInDown`}
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div
                className={`toast-header ${props.isError ? "error" : "success"}-toast-header justify-content-between`}
              >
                <i
                  className={`${props.isError ? "error" : "success"}-toastIcon far fa-${
                    props.isError ? "frown" : "smile"
                  }`}
                  aria-hidden="true"
                ></i>
                <div className={`toast-body ${props.isError ? "error" : "success"}-toast-body`}>
                  <strong className="mr-auto">{props.isError ? "Error!" : "Success!"}</strong>
                  <p className="mb-0">{returnError()}</p>
                </div>
                <button
                  type="button"
                  className={`close ${props.isError ? "error" : "success"}-close`}
                  data-dismiss="toast"
                  aria-label="Close"
                  onClick={() => closeAlert()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  message: state.navigation.alert.message,
  type: state.navigation.alert.type,
  autoDismiss: state.navigation.alert.autoDismiss,
  isError: state.navigation.alert.isError,
  isAlertOpen: state.navigation.alert.isAlertOpen
});

const mapDispatchToProps = (dispatch) => ({
  closeAlert: () => dispatch(navigationActions.clear())
});

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
