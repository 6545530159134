import React, { useState, Suspense, lazy } from "react";
import { useIntl } from "react-intl";
import FixedLoader from "./FixedLoader";
import { connect } from "react-redux";
import { error, getNotifications, success } from "../redux/actions/navigationActions";
import { logout } from "../redux/actions/userActions";
import { FaBars } from "react-icons/fa";
import { getCardView } from "../redux/services/prkServices";
import RolesAuth from "../components/RolesAuth";

import {
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Button
} from "reactstrap";
import { withRouter } from "react-router-dom";

const LogoutModal = lazy(() => import("./DeleteModal"));
const Notification = lazy(() => import("./Notification"));

const Main = (props) => {
  const {
    collapsed,
    handleToggleSidebar,
    handleCollapsedChange,
    location,
    user,
    history,
    logout,
    getNotifications,
    notifications,
    isFetchingNotifications,
    token,
    currentType
  } = props;

  let paths = location.pathname.split("/");

  const intl = useIntl();

  const [logoutModalProps, setLogoutModalProps] = useState({
    isModalOpen: false,
    altMethod: () => {}
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const [cardData, setCardData] = useState("");
  const [modelOpen, setModalOpen] = useState(false);

  const toggleLogoutModal = () =>
    setLogoutModalProps(Object.assign({}, logoutModalProps, { isModalOpen: !logoutModalProps.isModalOpen }));

  const _logout = () => {
    setLogoutModalProps(
      Object.assign({}, logoutModalProps, {
        isNotDelete: true,
        isModalOpen: !logoutModalProps.isModalOpen,
        altButtonText: "Logout",
        altMessage: (
          <>
            Logout <span className="text-red font-weight-bold">{` ${user.firstName} ${user.lastName}`}</span>
          </>
        ),
        altMethod: (cb) => {
          logout(history);
          cb && cb();
        }
      })
    );
  };

  const getPRKCardView = () => {
    getCardView(token).then(
      (data) => {
        if (data.code === 5) {
          setCardData(data.path);
          success();
        } else {
          error(data.message);
        }
      },
      (err) => error(err)
    );
  };

  return (
    <>
      <main>
        <header>
          <div className="topHeader">
            <div className="btn-toggle hamburger_btn" onClick={() => handleToggleSidebar(true)}>
              <FaBars />
            </div>
            <Navbar light expand="md">
              <i
                className="fa fa-bars p-2"
                id="menubars"
                checked={collapsed}
                onClick={() => handleCollapsedChange(!collapsed)}
              ></i>
              <NavbarBrand href={`/${paths[1] ? paths[1] : ""}`}>
                <span className="text-uppercase font-20">
                  {intl.formatMessage({ id: paths[1] ? paths[1] : "dashboard" })}
                </span>
              </NavbarBrand>

              <Navbar className="ml-auto mob">
                <Nav navbar className="nav-bar">
                  <Dropdown nav inNavbar isOpen={dropdownOpen} toggle={toggleDropDown}>
                    <DropdownToggle
                      nav
                      caret
                      className="newnotification p-0"
                      onClick={(e) => !dropdownOpen && getNotifications(token)}
                    >
                      <i className="fa fa-bell font23 headerImage mr-1 thead-icon"></i>
                      <span></span>
                    </DropdownToggle>

                    <DropdownMenu right className="xopplenotify notify_list">
                      <div className="notification-header">
                        <h5 className="notify_title">
                          <i className="fa fa-bell mr-2"></i>Notifications
                        </h5>
                      </div>

                      {isFetchingNotifications ? (
                        <p className="text-center">
                          <i className="fa fa-spinner fa-spin text-muted font19 text-center"></i>&nbsp; Loading...
                        </p>
                      ) : (
                        // <>
                        //   {notifications.length ? (
                        //     notifications.map((x, i) => (
                        //       <Notification
                        //         key={x._id + i}
                        //         notification={x}
                        //         setDropdownOpen={(e) => setDropdownOpen(e)}
                        //       />
                        //     ))
                        //   ) : (
                        //     <div className="text-center">
                        //       <span className="text-center noNofification">No Notification Found</span>
                        //     </div>
                        //   )}
                        // </>
                        <>
                          {(notifications._locationNotification && notifications._locationNotification.length > 0) ||
                          (notifications._mixNotification && notifications._mixNotification.length > 0) ? (
                            <>
                              {notifications._locationNotification &&
                                notifications._locationNotification.length > 0 &&
                                notifications._locationNotification.map((x, i) => (
                                  <Notification key={x._id + i} notification={x} />
                                ))}
                              {notifications._mixNotification &&
                                notifications._mixNotification.length > 0 &&
                                notifications._mixNotification.map((x, i) => (
                                  <Notification key={x._id + i} notification={x} />
                                ))}
                            </>
                          ) : (
                            <div className="text-center">
                              <span className="text-center noNofification">No Notification Found</span>
                            </div>
                          )}
                        </>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className="newnotification m-0 p-0">
                      <button
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="p-0 text-left d-flex align-items-center btn btn-link text-decoration-none"
                      >
                        <div className="d-block p-0 avatar-icon-wrapper">
                          <div className="avatar-icon rounded-sm">
                            <i className="fa fa-user font23 headerImage mr-0 thead-icon"></i>
                          </div>
                        </div>
                        <div className="d-none d-xl-block d-lg-block pl-2">
                          <span className="text-muted mb-2">
                            <small>{currentType.userTypeName}</small>
                          </span>
                          <div className="font-weight-bold text-blue text-capitalize mt-1">{` ${user.firstName} ${user.lastName}`}</div>
                        </div>
                        <div className="d-none d-xl-block d-lg-block pl-2">
                          <i className="fas fa-angle-down text-muted"></i>
                        </div>
                        <span className="pl-1 pl-xl-3"></span>
                      </button>
                    </DropdownToggle>
                    <DropdownMenu className="headerDropDown mr-2 py-2 profiledrop">
                      <DropdownItem className="text-blue pl-4 p-2">
                        <div className="font-weight-bold text-blue text-capitalize">{` ${user.firstName} ${user.lastName}`}</div>
                        <span className="text-muted mb-1 d-block">
                          <small className="userMainEmail">{user.email}</small>
                        </span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem className="py-2 pl-4 text-muted" onClick={() => history.push("/users/profile")}>
                        <span className="mainDropdownBtn">
                          <i className="fa fa-user"></i>
                        </span>
                        Profile
                      </DropdownItem>
                      <RolesAuth roleKey="prk">
                        <DropdownItem
                          onClick={(e) => {
                            setModalOpen(true);
                            getPRKCardView();
                          }}
                          className="py-2 pl-4 text-muted"
                        >
                          <span className="mainDropdownBtn">
                            <i className="far fa-credit-card"></i>
                          </span>
                          Card
                        </DropdownItem>
                      </RolesAuth>
                      <DropdownItem divider />
                      <DropdownItem
                        onClick={(e) => {
                          _logout();
                          e.preventDefault();
                        }}
                        className="py-2 pl-4 text-muted"
                      >
                        <span className="mainDropdownBtn">
                          <i className="fa fa-power-off"></i>
                        </span>
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Navbar>
            </Navbar>
          </div>
        </header>
        {props.children}
      </main>
      <Modal isOpen={logoutModalProps.isModalOpen} className="modal-small" center>
        <Suspense fallback={<FixedLoader />}>
          {logoutModalProps.isModalOpen && (
            <LogoutModal {...logoutModalProps} toggleModal={() => toggleLogoutModal()} />
          )}
        </Suspense>
      </Modal>
      <Modal isOpen={modelOpen} centered toggle={setModalOpen}>
        <ModalHeader>
          PRK Card
          <Button className="close" onClick={() => setModalOpen(false)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </Button>
        </ModalHeader>
        <ModalBody>
          <iframe frameBorder="0" scrolling="auto" title="PRK Card" src={cardData} width="100%" height="550px"></iframe>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  currentType: state.authentication.currentType,
  notifications: state.navigation.notifications.notifications,
  isFetchingNotifications: state.navigation.notifications.isFetchingNotifications,
  isFetching: state.navigation.alert.isFetching,
  token: state.authentication.token
});

const mapDispatchToProps = (dispatch) => ({
  logout: (history) => dispatch(logout(history)),
  getNotifications: (token) => dispatch(getNotifications(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
