import React, { useState, useEffect } from "react";

const FixedLoader = (props) => {
  const [loadingText, setLoadingText] = useState(" Loading...");
  useEffect(() => {
    if (props.noText) setLoadingText("");
    if (props.customText) setLoadingText(props.customText);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <i className="fa fa-spinner fa-spin text-muted font19"></i>
      {loadingText ? loadingText : ""}
    </>
  );
};

export default FixedLoader;
