import reducers from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";

let middlewares = [thunk];
if (process.env.NODE_ENV === "development") middlewares.push(logger);

const config = {
  reducer: reducers,
  devTools: process.env.NODE_ENV === "development",
  middleware: middlewares
};

const store = configureStore(config);

export default store;
