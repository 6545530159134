import React from "react";
import { render } from "react-dom";
import Router from "./route";
import "./css/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./css/fontawesome/css/all.min.css";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";

render(
  <Provider store={store}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </Provider>,
  document.querySelector("#root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
