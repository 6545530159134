import navigationConstants from "../constants/navigationConstants";

const {
  FETCHING,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_CLEAR,
  SET_USER_TYPES,
  IS_FETCHING_NOTIFICATIONS,
  FETCHED_NOTIFICATIONS,
  HANDLE_NOTIFICATION_CLICK,
} = navigationConstants;

export const navigationInitialState = {
  alert: {
    isFetching: false,
    type: "",
    message: "",
    isAlertOpen: false,
  },
  users: {
    userRoles: [],
    userTypes: [],
  },
  notifications: {
    notifications: [],
  },
};

const navigation = (state = navigationInitialState, action) => {
  switch (action.type) {
    case SET_USER_TYPES:
      return {
        ...state,
        users: {
          ...state.users,
          userTypes: action.payload.userTypes ? action.payload.userTypes : state.users.userTypes,
          userRoles: action.payload.userRoles ? action.payload.userRoles : state.users.userRoles,
        },
      };
    case FETCHING:
      return {
        ...state,
        alert: {
          ...state.alert,
          isFetching: true,
        },
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        alert: {
          isFetching: false,
          type: "primary",
          autoDismiss: true,
          isError: false,
          message: action.payload,
          isAlertOpen: true,
        },
      };
    case FETCH_FAILURE:
      return {
        ...state,
        alert: {
          isFetching: false,
          type: "danger",
          autoDismiss: false,
          isError: true,
          message: action.payload,
          isAlertOpen: true,
        },
      };
    case FETCH_CLEAR:
      return {
        ...state,
        alert: {
          isFetching: false,
          type: "",
          message: "",
          isAlertOpen: false,
        },
      };
    case IS_FETCHING_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isFetchingNotifications: true,
        },
      };
    case FETCHED_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          isFetchingNotifications: false,
          notifications: action.payload,
        },
      };
    case HANDLE_NOTIFICATION_CLICK:
      //
      //  "type" IS KEY OF THE ONE OF STATE MODULES OF "navigation", "value" IS AN OBJECT
      //
      return {
        ...state,
        [action.payload.type]: { ...state[action.payload.type], ...action.payload.value },
      };

    default:
      return state;
  }
};

export default navigation;
