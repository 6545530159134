import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

const RolesAuth = (props) => {
  const location = useLocation();
  const checkAuthorization = (userRoles, currentType, roleKey) => {
    if (userRoles.length > 0 && currentType) {
      if (currentType) {
        const currentRole = userRoles.find((x) => x.roleKey === roleKey);
        if (currentRole) {
          const subRoles = userRoles.filter((x) => x.subRoleId === currentRole.roleId).map((x) => x.roleId);
          const isAuthorized =
            currentType.roles.includes(currentRole.roleId) ||
            subRoles.reduce((acc, role) => acc || currentType.roles.includes(role), false);
          return isAuthorized ? props.children : "";
        }
        return <></>;
      }
      return <></>;
    }
    return <></>;
  };

  const { roleKey, currentType, userRoles, isFetching, mustUpdate } = props;

  // MEMOIZE RETURN VALUES FOR FUNCTION ARGUMENTS
  const children = useMemo(
    () => checkAuthorization(userRoles, currentType, roleKey, isFetching),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRoles, currentType, roleKey, isFetching, mustUpdate, location.pathname, props.children]
  );

  return children;
};

const mapStateToProps = (state) => ({
  isFetching: state.navigation.alert.isFetching,
  currentType: state.authentication.currentType,
  userRoles: state.navigation.users.userRoles
});

export default connect(mapStateToProps, null)(RolesAuth);
