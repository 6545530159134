import constants from "../constants";

const { userConstants } = constants;

export const authInitialState = {
  LoggedIn: false,
  isFetching: false,
  user: {},
  currentType: {},
  token: "",
  todayDate: "",
  captcha: ""
};

const authntication = (state = authInitialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        LoggedIn: false,
        user: {},
      };

    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        LoggedIn: true,
        user: action.payload.user,
        currentType: action.payload.currentType,
        token: action.payload.token,
        todayDate: action.payload.todayDate,
      };

    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        LoggedIn: false,
        user: {},
      };
    case userConstants.SET_CAPTCHA:
      return {
        ...state,
        captcha: action.payload
      };  
    case userConstants.LOGOUT:
      return {
        isFetching: false,
        LoggedIn: false,
      };
    default:
      return state;
  }
};

export default authntication;
