import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";

const secretKey = "sOV987RaviNWjR0AadD4rdxs01lwH365";

export const tz = (() => {
  if (Intl && Intl.DateTimeFormat()) return Intl.DateTimeFormat().resolvedOptions().timeZone;
  else return "America/New_York";
})();

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
}).format;

// export const formatPhoneNumber = (phoneNumberString) => {
//   const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
//   return phoneNumberString;
// };

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    const formattedNumber = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    if (!formattedNumber) return phoneNumberString;
    return formattedNumber;
  }
  else{
  return  phoneNumberString
  }
};

export const styles = {
  option: (styles) => ({ ...styles, textTransform: "capitalize", zIndex: "99999" }),
  control: (styles) => ({
    ...styles,
    boxShadow: "none",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderRadius: 0,
    borderBottomWidth: "1px",
    borderBottomColor: "#a4c9f3",
    ":active": { boxShadow: "none", border: "none" },
  }),
};

export const searchFilterRestricted = (e) => {
  let flag = e.key.search(/^[a-zA-Z0-9]+$/) === -1 ? false : true;
  if (!flag && ![".", "@", " "].includes(e.key)) e.preventDefault();
};

export const onlyNumbers = (string) => {
  if (string !== "") {
    var letters = /[0-9]/g;
    var str = string.match(letters);
    return str !== null ? str.toString().replace(/,/g, "") : 0;
  } else {
    return "";
  }
};

export const onlyAlpha = (string) => {
  if (string !== "") {
    var letters = /[a-z]/gi;
    var str = string.match(letters);
    return str !== null ? str.toString().replace(/,/g, "") : "";
  } else {
    return "";
  }
};

export const numberWithSpecialChar = (string, char) => {
  if (string !== "") {
    var letters = new RegExp("[0-9" + char + "]", "g");
    var str = string.match(letters);
    return str !== null ? str.toString().replace(/,/g, "") : "";
  } else {
    return "";
  }
};

export const alphaWithSpecialChar = (string, char) => {
  if (string !== "") {
    var letters = new RegExp("[a-z" + char + "]", "gi");
    var str = string.match(letters);
    return str !== null ? str.toString().replace(/,/g, "") : "";
  } else {
    return "";
  }
};

export const decryptResponse = (data) => {
  try {
    if(process.env.REACT_APP_ENV === "development"){
      return data
    }else{
      var bytes = CryptoAES.decrypt(data, secretKey);
      return JSON.parse(bytes.toString(CryptoENC));
    }
  } catch (error) {
    throw error;
  }
};

export const clearLocalStorage = (arrList) => {
  if(arrList == null){
    localStorage.clear()
  }
  else{
    if(arrList.length > 0){
      for(let x of arrList){
        localStorage.removeItem(x)
      }
    }
  }
}

export const getLogin = () => {
  let companyName = localStorage.getItem("companyName")
  if(companyName != null){
    return true
  }
  else{
    return false
  }
}

export const hideNumber = (value) => {
  
  let firstNumber = value.slice(0,-5)
  let lastNumber = value.slice(-5)

  if(firstNumber == "-"){
    firstNumber = "-"
  }
  else if(firstNumber == "$"){
    firstNumber = "$"
  }
  else if(firstNumber == "-$"){
    firstNumber = "-$"
  }
  else{
    firstNumber = firstNumber.split("").map((x)=>x=="$"?"$":x=="-"?"":"*").join("")
  }

  let r = firstNumber+lastNumber

  return r;
}

export const GETCITYSTATEAPI = "https://www.prominentgames.com/us_zip_city/get.php";
